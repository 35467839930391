import React from "react";
import Layout from "../../../other/layout";
import Hospitalite from "../../../components/AC-StaticPages/VotreEntreprise/Subpages/Hospitalite";
import SEO from "../../../other/seo";

export default function hospitalite() {
  return (
    <Layout>
      <SEO title="Hospitalité" />

      <Hospitalite />
    </Layout>
  );
}
